import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'
import { useAnimatedOnMouseOver } from 'src/utils/animationUtils'

import styles from './styles.module.css'

export default ({ savingsData }) => {
  const savingsStates = savingsData.savingsCards.value.map(() =>
    useAnimatedOnMouseOver()
  )
  
  return (
    <div className={styles.savingsContainer}>
      <div className={styles.title}>{savingsData.title.value}</div>
      <div className={styles.description}>{savingsData.description.value}</div>
      <div className={styles.cardsContainer}>
        {savingsData.savingsCards.value.map((savingsCard, index) => (
          <div
            key={savingsCard.value.title.value}
            className={classNames(styles.card, {
              [styles.smallCard]:
                index <
                savingsData.savingsCards.value.length -
                  (6 - savingsData.savingsCards.value.length),
              [styles.bigCard]:
                index >=
                savingsData.savingsCards.value.length -
                  (6 - savingsData.savingsCards.value.length),
            })}
            ref={savingsStates[index][1]}
          >
            <img src={savingsCard.value.gif.value.publicURL} alt="Animation amusante" />
            <div className={styles.cardTitle}>
              {savingsCard.value.title.value}
            </div>
            <div className={styles.cardDescription}>
              {savingsCard.value.description.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment Savings on CockpitService {
    savings {
      value {
        title {
          value
        }
        description {
          value
        }
        savingsCards {
          value {
            value {
              gif {
                value {
                  publicURL
                }
              }
              title {
                value
              }
              description {
                value
              }
            }
          }
        }
      }
    }
  }
`
