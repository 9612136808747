import { graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import GradientButton from 'src/components/GradientButton'

import styles from './styles.module.css'

export default ({
  planificationData,
  planificationButtonLink,
}) => {
  return (
    <div className={styles.planificationContainer}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{planificationData.title.value}</div>
        <div className={styles.subtitle}>
          {planificationData.subtitle.value}
        </div>
        <div className={styles.description}>
          {planificationData.description.value}
        </div>
        <GradientButton
          link={planificationButtonLink.value.to.value}
          text={planificationData.buttonText.value}
          buttonStyle={styles.buttonSize}
        />
      </div>
      <div className={styles.imageContainer}>
        <Img
          className={styles.image}
          fluid={planificationData.image.value.childImageSharp.fluid}
          alt="planification image"
        />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment Planification on CockpitService {
    planificationButtonLink {
      value {
        to {
          value
        }
      }
    }
    planification {
      value {
        title {
          value
        }
        subtitle {
          value
        }
        description {
          value
        }
        buttonText {
          value
        }
        image {
          value {
            childImageSharp {
              fluid(duotone: { highlight: "#00ecfa", shadow: "#2929e0" }) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
