import React from 'react'
import { graphql } from 'gatsby'
import GradientButton from 'src/components/GradientButton'

import styles from './styles.module.css'

export default ({
  personalInsuranceData,
  personnalInsuranceButtonLink,
}) => {
  return (
    <div className={styles.personalInsurance}>
      <div className={styles.header}>
        <div className={styles.title}>{personalInsuranceData.title.value}</div>
        <div className={styles.description}>
          {personalInsuranceData.description.value}
        </div>
        <GradientButton
          link={personnalInsuranceButtonLink.value.to.value}
          text={personalInsuranceData.buttonText.value}
          buttonStyle={styles.buttonSize}
        />
      </div>
      {personalInsuranceData.benefits.value.map(({ value: benefit }) => {
        return (
          <div className={styles.benefit} key={benefit.title.value}>
            <img src={benefit.gif.value.publicURL} alt="Animation amusante" />
            <div className={styles.benefitTitle}>{benefit.title.value}</div>
            <div className={styles.benefitDescription}>
              {benefit.description.value}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const query = graphql`
  fragment PersonalInsurance on CockpitService {
    personalInsurance {
      value {
        buttonText {
          value
        }
        benefits {
          value {
            value {
              description {
                value
              }
              gif {
                value {
                  publicURL
                }
              }
              title {
                value
              }
            }
          }
        }
        description {
          value
        }
        title {
          value
        }
      }
    }
    personnalInsuranceButtonLink {
      value {
        to {
          value
        }
      }
    }
  }
`
