import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'
import GradientButton from 'src/components/GradientButton'
import { useAnimatedOnMouseOver } from 'src/utils/animationUtils'

import styles from './styles.module.css'

export default ({ benefitsData }) => {
  return (
    <div className={styles.benefitContainer}>
      {benefitsData.map(benefit => {
        const benefitState = useAnimatedOnMouseOver()
        let image = null
        if (benefit.value.gif) {
          image = <img src={benefit.value.gif.value.publicURL} alt="Animation amusante" />
        }
        return (
          <div key={benefit.value.title.value} className={styles.benefit}>
            <div className={styles.imageContainer} ref={benefitState[1]}>
              {image}
            </div>
            <div className={styles.title}>{benefit.value.title.value}</div>
            <div>{benefit.value.description.value}</div>
            <div className={styles.buttonContainer}>
              {benefit.value.buttonText && (
                <GradientButton
                  link={benefit.value.link.value.to.value}
                  text={benefit.value.buttonText.value}
                  buttonStyle={classNames(
                    styles.buttonSize,
                    styles.buttonTextSize
                  )}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const query = graphql`
  fragment Benefits on CockpitService {
    benefits {
      value {
        value {
          animation {
            value {
              publicURL
            }
          }
          gif {
            value {
              publicURL
            }
          }
          title {
            value
          }
          description {
            value
          }
          buttonText {
            value
          }
          link {
            value {
              to {
                value
              }
            }
          }
        }
      }
    }
  }
`
