import { useState, useEffect, useRef } from 'react'

export function useAnimation(fileUrl) {
  const [file, setFile] = useState(undefined)

  useEffect(() => {
    (async function getFileUrl() {
      const res = await fetch(fileUrl)
      const data = await res.json()
      setFile(data)
    })()
  }, [fileUrl])

  return file
}

export function useAnimatedOnMouseOver() {
  const [paused, setPaused] = useState(true)
  const ref = useRef()
  const handleMouseOver = () => {
    setPaused(false)
  }
  const handleMouseLeave = () => {
    setPaused(true)
  }
  useEffect(() => {
    const refCurrent = ref.current
    if (refCurrent) {
      refCurrent.addEventListener('mouseover', handleMouseOver)
      refCurrent.addEventListener('mouseleave', handleMouseLeave)
    }
    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('mouseover', handleMouseOver)
        refCurrent.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [ref])
  return [paused, ref]
}
