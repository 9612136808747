import { graphql } from 'gatsby'
import React, { Component } from 'react'
import Layout from 'src/components/Layout'
import Header from 'src/components/Header'
import Planification from 'src/components/Services/Planification'
import Benefits from 'src/components/Services/Benefits'
import Savings from 'src/components/Services/Savings'
import PersonalInsurance from 'src/components/Services/PersonalInsurance'

class ServicePage extends Component {
  render() {
    const {
      pageContext,
      data: { service },
    } = this.props

    return (
      <Layout pageContext={pageContext}>
        <Header
          title={service.header.value.title.value}
          description={service.header.value.description.value}
        />
        <Planification
          planificationData={service.planification.value}
          planificationButtonLink={service.planificationButtonLink}
        />
        <Benefits benefitsData={service.benefits.value} />
        <Savings savingsData={service.savings.value} />
        <PersonalInsurance
          personalInsuranceData={service.personalInsurance.value}
          personnalInsuranceButtonLink={service.personnalInsuranceButtonLink}
        />
      </Layout>
    )
  }
}

export default ServicePage

export const query = graphql`
  query($locale: String) {
    service: cockpitService(lang: { eq: $locale }) {
      header {
        value {
          description {
            value
          }
          title {
            value
          }
        }
      }
      ...Planification
      ...Benefits
      ...Savings
      ...PersonalInsurance
    }
  }
`
